<template>
    <div class="flex-grow ml-2">
        <button type="button" @click="open()" class="inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
        Ready
        </button>
        <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-bold text-2xl text-white" id="modal-headline">
                                Ready selected orders
                            </h3>
                            <p class="mt-5">
                                Are you sure to set all selected orders to ready?
                            </p>
                        </div>
                    </div>
                    <div class="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <span class="flex w-full rounded-md sm:ml-3 sm:w-auto">
                            <button type="button" @click="submit()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-500 text-base leading-6 font-medium text-white hover:bg-green-400 focus:outline-none focus:border-green-600 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Yes, Ready
                            </button>
                        </span>
                        <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                            <button type="button" @click="close()" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Close
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['ids'],
    data() {
        return {
            active: false
        }
    },
    methods: {
        open() {
            this.active = true
        },
        close() {
            this.active = false
        },
        submit() {

            axios.post('/orderjobs/batch/ready', {ids: this.ids}).then(() => {
                this.$emit('reload')
                this.$emit('clearselection')
                this.close()
            }).catch(() => {
                console.log('error')
            })
        }
    }
}
</script>
