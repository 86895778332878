<template>
    <div class="bg-gray-900 grid grid-cols-3 h-5vh">
        <div class="col-span-1 col-start-2 flex justify-center">
            <img @click="increment()" class="h-10 p-2" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
        </div>
        <div class="col-span-1 flex justify-end p-2">
            <div class="mr-2">kitchenscreen</div>
            <button type="button" @click="setScreenkitchen()" aria-pressed="false" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" :class="{'bg-orange-500': screenkitchen}">
                <span class="sr-only">Kitchen</span>
                <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"  :class="{'translate-x-5': screenkitchen}"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            trigger: 0,
            screenkitchen: true,
        }
    },
    methods: {
        increment() {
            this.trigger++

            if (this.trigger == 1) {
                var self = this;
                setTimeout(function() {
                    self.trigger = 0
                }, 3000);
            }

            if (this.trigger == 5) {
                this.$store.dispatch('transaction/clear')
                this.$store.dispatch('auth/logout')

                this.$router.replace({ name: 'login' })
            }
        },
        setScreenkitchen() {
            this.screenkitchen = !this.screenkitchen
            this.$emit('screenkitchen', this.screenkitchen)
        }
    }
}
</script>
