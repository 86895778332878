<template>
    <div class="bg-gray-900 text-white flex flex-col h-screen justify-between">
        <navigation @screenkitchen="setScreenkitchen($event)" />
        <main class="px-2">

            <div>
                <!-- open orders -->
                <div v-if="screenkitchen" class="h-60vh overflow-auto">
                    <div class="text-left flex justify-start items-center">
                        <h2 class="text-2xl font-extrabold text-white tracking-tight">open orders ({{ open_orderjobs?.length }})</h2>
                    </div>
                    <draggable class="dragArea w-full flex justify-start" :list="open_orderjobs" @change="moved">
                        <div class="mr-2 p-2 pb-0 bg-gray-800 rounded-t-lg border-t border-l border-r" :class="{'border-yellow-300': selected_orderjobs.includes(orderjob.id)}" v-for="(orderjob, index) in open_orderjobs" :key="index">
                            <div class="w-52 space-y-6 xl:space-y-10">
                                <div class="space-y-2">
                                    <div>
                                        <div class="h-16 font-medium text-lg leading-6" @click="toggleOrderjob(orderjob.id)">
                                            <h3 class="text-white" v-if="orderjob.attributes.table_label">{{ orderjob.attributes.table_label }}</h3>
                                            <h3 class="text-base text-white italic">{{ moment(orderjob.attributes.timestamp).format('HH:mm:ss') }}</h3>
                                            <!-- <h3 class="text-base text-white italic">{{ orderjob.attributes.order_label }}</h3> -->
                                            <p class="text-green-400" :class="{
                                                'text-blue-400': (orderjob.attributes.status == 'open'),
                                                'text-green-400': (orderjob.attributes.status == 'ready'),
                                            }">{{ orderjob.attributes.status }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    <div class="dragArea w-full flex justify-start">
                        <div class="mr-2 p-2 pt-0 bg-gray-800 rounded-b-lg border-b border-l border-r" :class="{'border-yellow-300': selected_orderjobs.includes(orderjob.id)}" v-for="(orderjob, index) in open_orderjobs" :key="index">
                            <div class="w-52 space-y-6 xl:space-y-10">
                                <div class="space-y-2">
                                    <div>
                                        <ul :id="'orderjob_' + orderjob.id" class="text-xl h-35vh overflow-y-auto" @click="$refs.view.open(orderjob)">
                                            <li v-for="(line, line_index) in orderjob.attributes.lines" :key="line_index">
                                                <div v-if="line_index > 0" class="flex justify-center">
                                                    <div v-show="orderjob.attributes.lines[line_index - 1].display_order !== line.display_order" class="py-1"> ---- </div>
                                                </div>
                                                <div class="flex justify-start truncate">{{ Math.round(line.quantity) }}x - {{ line.description }}</div>
                                                <div v-if="line.notes" class="ml-3 text-left text-yellow-200">{{ line.notes }}</div>
                                                <div class="ml-3 text-left text-yellow-200" v-for="(kitchen_grocery, kitchen_grocery_index) in line.kitchen_groceries" :key="kitchen_grocery">
                                                    <div class="text-xs">{{kitchen_grocery_index}}:</div>
                                                    <div class="text-xs">{{ kitchen_grocery }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="flex justify-center h-5">
                                            <svg v-if="isOverflowing('orderjob_' + orderjob.id)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="flex justify-between">
                                        <park-modal :orderjob="orderjob" @reload="reload" />
                                        <prepare-modal :orderjob="orderjob" @reload="reload" v-if="orderjob.attributes.status == 'open'" />
                                        <ready-modal :orderjob="orderjob" @reload="reload" v-if="orderjob.attributes.status == 'in preparation'" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ready to serve orders -->
                <div v-if="!screenkitchen" class="h-60vh">
                    <div class="text-left pb-2">
                        <h2 class="text-2xl font-extrabold text-white tracking-tight">ready to serve ({{ ready_orderjobs?.length }})</h2>
                    </div>
                    <ul class="w-full flex justify-start overflow-auto">
                        <li class="h-82 mr-2 p-2 bg-gray-800 rounded-lg border" :class="{'border-yellow-300': selected_orderjobs.includes(orderjob.id)}" v-for="(orderjob, index) in ready_orderjobs.slice().reverse()" :key="index">
                            <div class="w-52 space-y-6 xl:space-y-10">
                                <div class="space-y-2">
                                    <div>
                                        <div class="font-medium text-lg leading-6 space-y-1" @click="toggleOrderjob(orderjob.id)">
                                            <h3 class="text-white text-green-400" v-if="orderjob.attributes.table_label">{{ orderjob.attributes.table_label }}</h3>
                                            <h3 class="text-white">{{ moment(orderjob.attributes.timestamp).format('HH:mm:ss') }}</h3>
                                            <!-- <h3 class="text-white">{{ orderjob.attributes.order_label }}</h3> -->
                                            <!-- <div class="h-5"> -->
                                                <!-- <p v-if="orderjob.attributes.table_label" class="text-green-400">{{ orderjob.attributes.table_label }}</p> -->
                                            <!-- </div> -->
                                        </div>
                                        <ul :id="'orderjob_' + orderjob.id" class="h-35vh overflow-y-auto" @click="$refs.view.open(orderjob)">
                                            <li v-for="(line, line_index) in orderjob.attributes.lines" :key="line_index">
                                                <div v-if="line_index > 0" class="flex justify-center">
                                                    <div v-show="orderjob.attributes.lines[line_index - 1].display_order !== line.display_order" class="py-1"> ---- </div>
                                                </div>
                                                <div class="flex justify-start truncate">{{ Math.round(line.quantity) }}x - {{ line.description }}</div>
                                                <div v-if="line.notes" class="ml-3 text-left text-yellow-200">{{ line.notes }}</div>
                                                <div class="ml-3 text-left text-yellow-200" v-for="(kitchen_grocery, kitchen_grocery_index) in line.kitchen_groceries" :key="kitchen_grocery">
                                                    <div class="text-xs">{{kitchen_grocery_index}}:</div>
                                                    <div class="text-xs">{{ kitchen_grocery }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="flex justify-between">
                                        <serve-modal :orderjob="orderjob" @reload="reload" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- parked orders -->
                <div v-if="screenkitchen" class="py-2 h-10vh">
                    <div class="text-left pb-2">
                        <h2 class="text-lg font-extrabold text-white tracking-tight">parked orders ({{ parked_orderjobs?.length }})</h2>
                    </div>
                    <ul class="w-full flex justify-start overflow-auto">
                        <li class="mr-2 p-2 bg-gray-800 rounded-lg" v-for="(orderjob, index) in parked_orderjobs" :key="index">
                            <div class="w-52 space-y-6 xl:space-y-10">
                                <div class="space-y-2">
                                    <div class="font-medium text-sm leading-6 space-y-1">
                                        <div class="flex justify-between">
                                            <h3 class="text-white">{{ orderjob.attributes.table_label }}</h3>
                                            <!-- <h3 class="text-white">{{ orderjob.attributes.order_label }}</h3> -->
                                            <h3 class="text-white">{{ moment(orderjob.attributes.timestamp).format('HH:mm:ss') }}</h3>
                                        </div>
                                        <reopen-modal :orderjob="orderjob" @reload="reload" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- recent completed orders -->
                <div v-if="screenkitchen" class="py-2 h-15vh">
                    <div class="text-left pb-2">
                        <h2 class="text-lg font-extrabold text-white tracking-tight">recent completed orders ({{ recentready_orderjobs?.length }})</h2>
                    </div>
                    <ul class="w-full flex justify-start items-start overflow-auto">
                        <li class="mr-2 p-2 bg-gray-800 rounded-lg" v-for="(orderjob, index) in recentready_orderjobs" :key="index">
                            <div class="w-52 space-y-6 xl:space-y-10">
                                <div class="space-y-2">
                                    <div class="font-medium text-sm leading-6 space-y-1">
                                        <div class="flex justify-between">
                                            <h3 class="text-white">{{ orderjob.attributes.table_label }}</h3>
                                            <h3 class="text-white">{{ moment(orderjob.attributes.timestamp).format('HH:mm:ss') }}</h3>
                                            <!-- <h3 class="text-white">{{ orderjob.attributes.order_label }}</h3> -->
                                        </div>
                                        <div class="flex justify-between">
                                            <h3 class="text-white">{{ orderjob.attributes.status }}</h3>
                                            <reopen-modal :orderjob="orderjob" @reload="reload" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>

        </main>
        <!-- footer -->
        <div class="h-10vh p-3 bg-gray-800 flex justify-between items-center">
            <div>
                Orders loaded at: {{ loaded_at }}
            </div>
            <div class="flex justify-end">
                <div v-if="screenkitchen">
                    <prepare-batch-modal :ids="selected_orderjobs" @reload="reload" @clearselection="clearSelection" />
                </div>
                <div v-if="screenkitchen">
                    <ready-batch-modal :ids="selected_orderjobs" @reload="reload" @clearselection="clearSelection" />
                </div>
                <div v-if="!screenkitchen">
                    <serve-batch-modal :ids="selected_orderjobs" @reload="reload" @clearselection="clearSelection" />
                </div>
            </div>
        </div>
        <!-- modals -->
        <view-modal ref="view" />
    </div>
</template>

<style scoped>
.openorder {
    width: 220px;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import { VueDraggableNext } from 'vue-draggable-next'

import Navigation from '../components/Navigation.vue';
import PrepareModal from '../modals/Prepare.vue';
import PrepareBatchModal from '../modals/PrepareBatch.vue';
import ReadyBatchModal from '../modals/ReadyBatch.vue';
import ServeBatchModal from '../modals/ServeBatch.vue';
import ParkModal from '../modals/Park.vue';
import ReadyModal from '../modals/Ready.vue';
import ReopenModal from '../modals/Reopen.vue';
import ServeModal from '../modals/Serve.vue';
import ViewModal from '../modals/View.vue';

// components
// import Navigation from '../components/Navigation'

// modals
// import ModalItem from '../modals/Item'
// import ModalShoppingcart from '../modals/Shoppingcart'

export default {
    name: 'Orders',
    components: {
        draggable: VueDraggableNext,
        Navigation,
        ParkModal,
        PrepareModal,
        PrepareBatchModal,
        ReadyBatchModal,
        ServeBatchModal,
        ReadyModal,
        ReopenModal,
        ServeModal,
        ViewModal,
        // Navigation,
        // ModalItem,
        // ModalShoppingcart,
    },
    data () {
        return {
            loaded_at: null,
            open_orderjobs: [],
            parked_orderjobs: [],
            ready_orderjobs: [],
            recentready_orderjobs: [],
            selected_orderjobs: [],
            screenkitchen: true
        }
    },
    created () {
        this.timer = setInterval(this.reload, 15000)
        this.timer_clerk = setInterval(this.reloadClerk, 5000)
    },

    mounted() {
        this.reload()
        this.reloadClerk()
    },

    methods: {
        viewOrderjob(orderjob) {
            this.$refs.view.open(orderjob)
        },

        reload() {
            if (this.screenkitchen) {
                this.getOrderjobs('open');
                this.getOrderjobs('parked');
                this.getOrderjobs('recentready');
            }
        },

        reloadClerk() {
            if (!this.screenkitchen) {
                this.getOrderjobs('ready');
            }
        },

        clearSelection() {
            this.selected_orderjobs = []
        },

        getOrderjobs(type) {
            let previous_open_orderjobs = this.open_orderjobs.length

            axios.get('/orderjobs/' + type).then(response => {
                this.loaded_at = moment().format('HH:mm:ss')
                this[type + '_orderjobs'] = response.data.data

                if (type == 'open' && this.open_orderjobs.length > previous_open_orderjobs) {
                    var audio = new Audio(require('../assets/audio/notification.wav'))
                    audio.play()
                }
            }).catch(() => {
                console.log('error')
            })
        },

        toggleOrderjob(id) {
            var index = this.selected_orderjobs.indexOf(id);

            if (index === -1) {
                this.selected_orderjobs.push(id);
            } else {
                this.selected_orderjobs.splice(index, 1);
            }
        },

        setScreenkitchen(event) {
            this.screenkitchen = event
            this.clearSelection()
            this.reload()
            this.reloadClerk()
        },


        isOverflowing(selector) {
            var element = this.$el.querySelector('#' + selector)
            if (element) {
                return (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
            }
        },


        moved(event) {
            console.log('moved', event)
            if (this.open_orderjobs[event.moved.newIndex + 1]) {
                let timestamp = moment(this.open_orderjobs[event.moved.newIndex + 1].attributes.timestamp).subtract(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')

                axios.post('/orderjobs/' + this.open_orderjobs[event.moved.newIndex].id + '/timestamp', {
                    timestamp: timestamp
                }).then(() => {
                }).catch(() => {
                    console.log('error')
                }).finally(() => {
                    this.reload()
                })
            }
             else if (this.open_orderjobs[event.moved.newIndex - 1]) {
                let timestamp = moment(this.open_orderjobs[event.moved.newIndex - 1].attributes.timestamp).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')

                axios.post('/orderjobs/' + this.open_orderjobs[event.moved.newIndex].id + '/timestamp', {
                    timestamp: timestamp
                }).then(() => {
                }).catch(() => {
                    console.log('error')
                }).finally(() => {
                    this.reload()
                })
            }
        },

        moment(date) {
            return moment(date);
        }
    },
}
</script>
