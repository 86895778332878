<template>
    <div>
        <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 class="text-lg leading-6 font-bold text-2xl text-white" id="modal-headline">
                                {{ orderjob.attributes.order_label}} <span v-if="orderjob.attributes.table_label">- {{ orderjob.attributes.table_label }}</span>
                            </h3>
                            <div class="max-h-60vh overflow-auto w-full mt-5 p-2 rounded text-xl bg-gray-800">
                                <ul>
                                    <li v-for="(line, line_index) in orderjob.attributes.lines" :key="line_index">
                                        <div class="flex justify-start truncate">
                                            <div :class="{'ml-2': line.addon}">{{ Math.round(line.quantity) }}x - {{ line.description }}</div>
                                        </div>
                                        <div v-if="line.notes" class="ml-3 text-left text-yellow-200">{{ line.notes }}</div>
                                            <div class="ml-3 text-left text-yellow-200" v-for="(kitchen_grocery, kitchen_grocery_index) in line.kitchen_groceries" :key="kitchen_grocery">
                                                <div class="text-xs">{{kitchen_grocery_index}}:</div>
                                                <div class="text-xs">{{ kitchen_grocery }}</div>
                                            </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <span class="mt-3 flex w-full rounded-md sm:mt-0 sm:w-auto">
                            <button type="button" @click="close()" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                Close
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderjob: null,
            active: false
        }
    },
    methods: {
        open(orderjob) {
            this.orderjob = orderjob
            this.active = true
        },
        close() {
            this.active = false
        },
    }
}
</script>
